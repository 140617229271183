@import url("../fonts/GTWalsheimPro/stylesheet.css");

body {
  background-color: #f6f8fa !important;
  font: 15px "GT Walsheim Pro", Arial, Helvetica, sans-serif !important;
  padding: unset !important;
  color:unset !important;
  -webkit-user-select: none;
          user-select: none;
}
input:disabled {
  background: #fff !important;
}

.modal-backdrop.show {
  opacity: 0.3;
  background-color: var(--color-primary);
}
.carousel .control-dots .dot{
  background: var(--color-primary);
}
div:focus {
  outline: none !important;
}
.wrapMyWord{
  overflow: hidden;
  text-overflow: ellipsis;
}
#root {
  max-width: 1920px;
  margin: 0 auto;
}
.modal-content {
  border-radius: 10px;
}
/*
* Global Remove Number field arrow
*/
.yt-fp-form
  .yt-number-filed.form-group
  .form-control::-webkit-outer-spin-button,
.yt-fp-form
  .yt-number-filed.form-group
  .form-control::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/*SCROLLBAR-START/
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: var(--color-primary);
  border-radius: 5px;
}
/*SCROLLBAR-END/

/* Firefox */
.yt-fp-form .yt-number-filed.form-group .form-control[type="number"] {
  -moz-appearance: textfield;
}

.ellises {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.styles_scroll-to-top__2A70v:focus{
  background-color: var(--color-primary);
}
.styles_scroll-to-top__2A70v:focus svg{
  fill: white;
}
.css-1mf073k-ToastElement{
  background-image: linear-gradient(
    315deg
    , #3bb78f 26%, #0bab64 82%) !important;
  opacity: 95%;
  box-shadow: 0 3px 8px rgb(105 161 137) !important;
  color: white !important;
}
.css-3mfg68-Icon{
  background-color: transparent !important;
}
.css-1e3oc50-ToastElement{
  background-image: linear-gradient( 
315deg
 , #77212b 13%, #a71d31 82%) !important;
    opacity: 95% !important;
    box-shadow: 0 4px 8px #cc7b86 !important;
    color: white !important;
}
.css-1cbc3rp-Icon{
  background-color: transparent !important;
}


p.sp-description-text.img-w-100 img {
  max-width: 100% !important;
}

/*END*/
@media (min-width: 576px) {
  .cm-small-modal {
    max-width: 350px;
  }
}
@media (min-width: 768px) {
  .cm-small-modal-6 {
    max-width: 600px;
  }
}
@media (min-width: 768px) {
  .cm-small-modal-4 {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 767.92px) {
  .cm-small-modal-4 {
    max-width: 286px;
    margin-left: auto;
    margin-right: auto;
  }
  .cm-small-modal-6 {
    max-width: 335px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 1281px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 93.3%;
  }
}
@media (min-width: 1900px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 88.9%;
  }
}
@media (max-width: 1280px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
}
