/*
* Global CSS
*/
.yt-single-offer-component[data-v-c0c17ce8] {
  position: relative;
}
.yt-soc-title[data-v-c0c17ce8] {
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: normal;
  text-align: left;
  color: #3e454f  ;
}
.yt-soc-offer[data-v-c0c17ce8] {
  font-size: 20px;
  line-height: 1.14;
  color: #3e454f
}
.yt-soc-btn[data-v-c0c17ce8] {
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--color-primary);
  width: 100%;
  max-width: 170px;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: 0.36px;
  margin-top: 41px;
  text-align: center;
  color: #ffffff;
  transition: .5s ease-in-out;
}
.yt-soc-btn[data-v-c0c17ce8]:hover{
  background-color: white;
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
}
.yt-soc-inner-content[data-v-c0c17ce8] {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.yt-sb-inner-wrap[data-v-c0c17ce8] {
  position: absolute;
  margin: 11% 0% 0% 6%;
}
.product-img[data-v-c0c17ce8]{
  width: 45%;
  float: right;
}
.deal-of-day[data-v-c0c17ce8]{
  color: var(--color-primary)
}
.hoverMe[data-v-c0c17ce8]{
  cursor: pointer;
}
@media (min-width: 1368px) and (max-width: 1900px) {
.yt-soc-btn[data-v-c0c17ce8] {
    font-size: 16px;
    line-height: 1.13;
}
}
@media (min-width: 1900px) {
.yt-soc-btn[data-v-c0c17ce8] {
    max-width: 180px;
    margin-top: 57px;
    font-size: 18px;
    line-height: 1.11;
}
.yt-soc-title[data-v-c0c17ce8] {
    font-size: 40px;
    font-weight: 500;
    line-height: 1.14;
}
.yt-soc-offer[data-v-c0c17ce8] {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.14;
}
.yt-soc-btn[data-v-c0c17ce8] {
    margin-top: 57px;
}
.yt-sb-inner-wrap[data-v-c0c17ce8] {
    top: 165px;
}
}

/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.yt-sb-inner-wrap[data-v-c0c17ce8] {
    top: 20px;
}
.yt-soc-title[data-v-c0c17ce8] {
    font-size: 30px;
    margin-bottom: 11px !important;
    line-height: 1.15;
}
.yt-soc-offer[data-v-c0c17ce8] {
    font-size: 20px;
    line-height: 1.14;
}
.yt-soc-btn[data-v-c0c17ce8] {
    font-size: 16px;
    line-height: 1.13;
    margin-top: 16px !important;
    padding-top: 10px !important;
    padding-bottom: 12px !important;
    width: 100%;
    max-width: 150px;
}
.yt-single-offer-component[data-v-c0c17ce8] {
    margin: 20px 0 !important;
}
}
@media (max-width: 1087px) {
.yt-soc-title[data-v-c0c17ce8]{
    font-size: 25px;
}
.yt-soc-offer[data-v-c0c17ce8]{
    font-size: 17px;
}
.yt-soc-btn[data-v-c0c17ce8]{
    font-size: 14px;
    margin-top: 5px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    max-width: 127px;
}
}
@media (max-width:900px) {
.yt-sb-inner-wrap[data-v-c0c17ce8]{
    top: 0;
}
.yt-soc-title[data-v-c0c17ce8]{
    font-size: 18px;
    line-height: 1;
    margin-bottom: 8px !important;
}
.yt-soc-offer[data-v-c0c17ce8]{
    font-size: 14px;
    line-height: 1;
}
.yt-soc-btn[data-v-c0c17ce8]{
    font-size: 14px;
    margin-top: 5px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    max-width: 127px;
}
}
/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.88px) {
.yt-sb-inner-wrap[data-v-c0c17ce8] {
    margin: 16% 0% 0% 0%;
    width: 150px;
}
.yt-soc-offer[data-v-c0c17ce8] {
    font-size: 10px;
    line-height: 1;
}
.yt-soc-title[data-v-c0c17ce8]{
    font-size: 14px;
}
.yt-soc-btn[data-v-c0c17ce8] {
    font-size: 12px;
    line-height: 1;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    max-width: 100px;
}
}
@media (max-width: 576px) {
.yt-single-offer-component[data-v-c0c17ce8] {
    background-size: cover;
    min-height: 186px;
    background-position: center top;
    background-repeat: no-repeat;
}
}
