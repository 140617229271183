/*
* YT Product Slider CSS
*/
.yt-product-fav[data-v-ba8fa95a] {
  font-size: 24px;
  color: var(--color-dark-grey);
  cursor: pointer;
}
.yt-comonent-link[data-v-ba8fa95a] {
  margin-right: 8%;
}
.yt-product-off[data-v-ba8fa95a] {
  /* background-color: var(--color-primary); */
  font-size: 18px;
  font-weight: 500;
  color: var(--color-primary);
  line-height: 1.11;
  min-width: 66px;
  /* border-top-right-radius: 50px;
  border-bottom-right-radius: 50px; */
  margin-left: -1px;
  white-space: nowrap
}
.yt-product-slider-cpnt[data-v-ba8fa95a] {
  border: 1px solid white;
  background-color: #fff;
  border-radius: 4px;
  width: 100%;
  /* padding-bottom: 50px; */
  transition: all 0.55s ease;
}
.yt-product-slider-cpnt[data-v-ba8fa95a]:hover {
  box-shadow: 0 10px 50px -12px rgba(0, 0, 0, 0.1);
  /* transform: translateY(-10px); */
  transition: all 0.55s ease;
}
.yt-component-wrapper[data-v-ba8fa95a] {
  border-radius: 10px;
  position: relative;
}
.rec-item-wrapper[data-v-ba8fa95a] {
  outline: none !important;
  box-shadow: none;
}
.yt-product-bg-image[data-v-ba8fa95a] {
  width: 100%;
  height: 250px;
  background-size: contain;
  background-position: center top;
  margin: auto;
  cursor: pointer;
  max-width: 184px;
}
.yt-slider-left[data-v-ba8fa95a],
.yt-slider-right[data-v-ba8fa95a] {
  position: absolute;
  font-size: 35px;
  cursor: pointer;
  color: var(--color-primary);
}
.yt-item-slider[data-v-ba8fa95a] {
  position: relative;
  width: 100%;
}
.slider-container[data-v-ba8fa95a] {
  width: 100%;
  margin: 0px 10px;
  padding-top: 20px;
}
.yt-product-title[data-v-ba8fa95a] {
  font-size: 18px;
  line-height: 0.95;
  font-weight: bold;
  color: var(--color-black);
}
.yt-product-code[data-v-ba8fa95a] {
  color: silver;
}
.yt-product-rating[data-v-ba8fa95a] {
  color: dimgray;
}
.rating-star-icon[data-v-ba8fa95a] {
  color: var(--color-gold);
  vertical-align: text-top;
}
.yt-component-more[data-v-ba8fa95a] {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.13;
  text-align: right;
  border-radius: 0;
  color: var(--color-dark-grey);
}
.yt-comonent-top-title[data-v-ba8fa95a] {
  font-size: 35px;
  font-weight: 500;
  line-height: 1.13;
  text-align: left;
  color: var(--color-default);
}
.yt-td-product-img[data-v-ba8fa95a] {
  cursor: pointer;
  width: 100%;
  height: 250px;
  object-fit: cover;
  object-position: top;
}
.yt-product-sale-price[data-v-ba8fa95a] {
  color: var(--color-blue);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
}
.yt-product-slider-cpnt[data-v-ba8fa95a]:hover {
  border: 1px solid var(--color-primary);
}
.yt-product-slider-cpnt:hover .yt-product-sale-price[data-v-ba8fa95a] {
  color: var(--color-primary);
}
.yt-product-reg-price2[data-v-ba8fa95a] {
  padding-left: 8px;
  color: darkgray;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  text-decoration: line-through;
}
.yt-product-add-btn[data-v-ba8fa95a] {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.13;
  letter-spacing: 0.36px;
  color: dimgray;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border: 0px;
  border-top: 1.5px solid lightgray;
  background-color: white;
}
.yt-item-slider:hover .yt-product-add-btn[data-v-ba8fa95a] {
  background-color: var(--button-primary);
  color: var(--button-text-primary);
  border: 0px;
}
.yt-produstslider-info[data-v-ba8fa95a] {
  padding-bottom: 0px;
}
.yt-top-offer-whl[data-v-ba8fa95a] {
  min-height: 42px;
}
.hp-product-slider[data-v-ba8fa95a] {
  margin: 60px 0;
}
@media (min-width: 1900px) {
.yt-product-add-btn[data-v-ba8fa95a] {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.11;
}
.yt-item-slider[data-v-ba8fa95a] {
    margin: 0 15px;
}
}
@media (min-width: 1281px) {
.yt-slider-left[data-v-ba8fa95a],
  .yt-slider-right[data-v-ba8fa95a] {
    top: -5%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}
.yt-slider-left[data-v-ba8fa95a] {
    right: 4%;
}
.yt-slider-right[data-v-ba8fa95a] {
    right: 1%;
}
}
@media (max-width: 1280px) {
.yt-slider-left[data-v-ba8fa95a],
  .yt-slider-right[data-v-ba8fa95a] {
    top: -56px;
}
.yt-slider-left[data-v-ba8fa95a] {
    right: 43px;
}
.yt-slider-right[data-v-ba8fa95a] {
    right: 0;
}
.yt-comonent-link[data-v-ba8fa95a] {
    margin-right: 8%;
}
}

/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.yt-slider-right[data-v-ba8fa95a] {
    right: 20px;
}
.yt-comonent-top-title[data-v-ba8fa95a] {
    font-size: 25px;
    line-height: 1.13;
}
.yt-component-more[data-v-ba8fa95a] {
    font-size: 18px;
    line-height: 1.14;
}
.yt-slider-left[data-v-ba8fa95a],
  .yt-slider-right[data-v-ba8fa95a] {
    font-size: 21px;
    top: -41px;
}
.yt-slider-left[data-v-ba8fa95a] {
    right: 42px;
}
.yt-comonent-link[data-v-ba8fa95a] {
    margin-right: 8%;
}
.yt-product-off[data-v-ba8fa95a] {
    font-size: 12px;
    line-height: 1.17;
}
.yt-sglproduct-fav[data-v-ba8fa95a] {
    font-size: 15px;
}
.yt-product-title[data-v-ba8fa95a] {
    font-size: 14px;
    line-height: 0.71;
    margin-top: 15px !important;
}
.yt-price-wrap[data-v-ba8fa95a] {
    padding: 8px 0px 16px !important;
}
.yt-product-reg-price1[data-v-ba8fa95a],
  .yt-product-reg-price2[data-v-ba8fa95a] {
    font-size: 12px;
    line-height: 1.17;
}
.yt-product-slider-cpnt[data-v-ba8fa95a] {
    padding-bottom: 40px;
}
.yt-top-offer-whl[data-v-ba8fa95a] {
    margin-top: 10px !important;
}
.yt-product-add-btn[data-v-ba8fa95a] {
    font-size: 14px;
    line-height: 1.14;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
}
.yt-produstslider-info[data-v-ba8fa95a] {
    padding-bottom: 15px;
}
.yt-component-wrapper.yt-slider-component[data-v-ba8fa95a] {
    margin-left: -20px;
    margin-right: -20px;
    border-radius: 0;
}
section.hp-product-slider[data-v-ba8fa95a] {
    margin: 20px 0 !important;
}
.yt-td-product-img[data-v-ba8fa95a] {
    width: 100%;
    height: 125px;
}
}

/*
* Screen for 768 Resolution 
*/
@media (max-width: 767.98px) {
.yt-td-product-img[data-v-ba8fa95a] {
    width: 100%;
    height: 100px;
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.yt-product-add-btn[data-v-ba8fa95a] {
    font-size: 13px;
    line-height: 1.15;
}
section.hp-product-slider[data-v-ba8fa95a] {
    position: relative;
}
.yt-product-slider-cpnt[data-v-ba8fa95a] {
    padding-bottom: 0;
}
.yt-price-wrap[data-v-ba8fa95a]{
    height: 60px;
    padding: 8px 10px 16px !important;
}
.yt-product-bg-image[data-v-ba8fa95a] {
    height: 200px;
}
.yt-component-wrapper.yt-slider-component[data-v-ba8fa95a] {
    padding-top: 52px !important;
}
.yt-produstslider-info[data-v-ba8fa95a] {
    position: absolute;
    left: 20px;
    right: 20px;
    top: 18px;
    /* z-index: 5; */
}
.yt-slider-left[data-v-ba8fa95a],
  .yt-slider-right[data-v-ba8fa95a] {
    top: 17px;
    /* z-index: 10; */
}
.yt-component-more[data-v-ba8fa95a] {
    border-bottom: none;
    font-size: 15px;
    line-height: 1.2;
    padding: 0 !important;
    margin: 0 !important;
}
.yt-comonent-top-title[data-v-ba8fa95a] {
    font-size: 17px;
    line-height: 1.12;
}
.yt-slider-left[data-v-ba8fa95a] {
    right: 41px;
}
.yt-comonent-link[data-v-ba8fa95a] {
    margin-right: 16%;
}
.yt-product-title[data-v-ba8fa95a] {
    font-size: 16px;
    line-height: 1.19;
}
.yt-product-sale-price[data-v-ba8fa95a],
  .yt-product-reg-price[data-v-ba8fa95a] {
    font-size: 15px;
    line-height: 1.2;
}
.yt-product-off[data-v-ba8fa95a] {
    min-width: 35px;
}
.yt-item-slider[data-v-ba8fa95a] {
    margin: 0 5px;
}
.yt-top-offer-whl[data-v-ba8fa95a] {
    margin-top: 0 !important;
}
section.hp-product-slider[data-v-ba8fa95a] {
    margin: 10px 0 !important;
}
}
@media (min-width: 1366px) and (max-width: 1900px) {
.yt-product-title[data-v-ba8fa95a] {
    font-size: 18px;
    padding-top: 5px;
}
.yt-product-slider-cpnt .price.yt-price-wrap[data-v-ba8fa95a] {
    margin-bottom: 7px;
}
.yt-product-off[data-v-ba8fa95a] {
    font-size: 16px;
    min-width: 50px;
}
}
