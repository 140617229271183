/*
* Global CSS of Help Center
*/
.p-40[data-v-2be4439d] {
  padding: 40px;
}
.profile-pg-inner-wrap[data-v-2be4439d] {
  border: 1px solid #e6e6e6;
}
/*
* Breadcrumbs CSS
*/
.yt-mb-80[data-v-2be4439d] {
  margin-bottom: 80px;
}
.profile-pg-breadcrumbs[data-v-2be4439d] {
  color: var(--color-dark-grey);
  font-size: 12px;
  line-height: 1.17;
}
.profile-pg-current[data-v-2be4439d] {
  color: var(--color-black);
  font-size: 12px;
  font-weight: 500;
  line-height: 1.17;
}
.profile-pg-beckfrom-ct-page[data-v-2be4439d] {
  margin-top: 50px;
  color: var(--color-black);
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
}
.profile-pg-mb-10[data-v-2be4439d] {
  margin-bottom: 10px;
}
.profile-pg-mb-30[data-v-2be4439d] {
  margin-bottom: 30px;
}
.profile-pg-mt-30[data-v-2be4439d] {
  margin-bottom: 30px;
}
.profile-pg-mb-50[data-v-2be4439d] {
  margin-bottom: 50px;
}
.radius-10[data-v-2be4439d] {
  border-radius: 10px;
}
.list-style-none[data-v-2be4439d] {
  list-style: none;
}
.profile-pg-inner-contnet .profile-pg-tabs-name li[data-v-2be4439d]:first-child {
  padding-top: 0;
}
/*
* Left Col CSS
*/
.profile-pg-inner-contnet .profile-pg-tabs-name li[data-v-2be4439d] {
  padding: 12.5px 0;
  border-bottom: 1px solid #dbdbdb;
  font-size: 18px;
  line-height: 1.11;
  color: var(--color-black);
  cursor: pointer;
  position: relative;
  font-weight: 500;
}
.profile-pg-inner-contnet .pg-profile-box li:first-child.active[data-v-2be4439d]::after {
  opacity: 0;
}
.profile-pg-inner-contnet .profile-pg-tabs-name li.active[data-v-2be4439d]::after {
  content: "";
  height: 75%;
  width: 3px;
  background: var(--color-primary);
  position: absolute;
  top: 50%;
  right: -40px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.profile-pg-inner-contnet .profile-pg-tabs-name li[data-v-2be4439d]:last-child {
  border: none;
  padding-bottom: 0;
}
.profile-pg-inner-contnet .profile-pg-tabs-name li.active[data-v-2be4439d] {
  font-weight: 500;
  color: var(--color-primary);
}

/*
* Right Col CSS
*/
.profile-pg-tab-main-title[data-v-2be4439d] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-black);
}
.profile-pg-tab-sub-title[data-v-2be4439d] {
  font-size: 20px;
  font-weight: 500;
  color: var(--color-black);
  line-height: 1.15;
}
.ht-tab-description[data-v-2be4439d] {
  font-size: 18px;
  line-height: 1.67;
  letter-spacing: 0.18px;
  color: var(--color-dark-grey);
}

/*
*/
.img-upload svg[data-v-2be4439d] {
  font-size: 34px;
  color: var(--color-black);
}
.img-upload[data-v-2be4439d] {
  width: 109px;
  height: 109px;
  background-color: #f6f8fa;
  border-radius: 6px;
}
.profile-name[data-v-2be4439d] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-black);
}
.profile-email[data-v-2be4439d] {
  font-size: 18px;
  line-height: 1.11;
  color: var(--color-dark-grey);
}
.profile-item-wrap[data-v-2be4439d] {
  position: relative;
}
.profile-notiy[data-v-2be4439d] {
  position: absolute;
  background-color: var(--color-primary);
  font-size: 13px;
  line-height: 1.15;
  color: var(--color-text-primary);
  padding: 5px;
  border-radius: 50px;
  top: -6px;
  left: 24px;
  width: 20px;
  text-align: center;
}
.profile-pg-title[data-v-2be4439d] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-black);
}
.profile-data-tag[data-v-2be4439d] {
  font-size: 14px;
  line-height: 1.14;
  color: var(--color-dark-grey);
}
.profile-user-name[data-v-2be4439d] {
  font-size: 18px;
  line-height: 1.11;
  color: var(--color-black);
  border-bottom: 2px solid #f6f8fa;
}
.profile-edit-btn[data-v-2be4439d] {
  border-radius: 6px;
  border-color: var(--button-primary);
  background-color: var(--button-primary);
  color: var(--button-text-primary);
  width: 100%;
  max-width: 180px;
}
.profile-edit-btn[data-v-2be4439d]:hover {
  background-color: white;
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
}
.profile-edit-pass[data-v-2be4439d] {
  color: var(--color-primary);
}
.profile-edit-pass[data-v-2be4439d],
.profile-edit-btn[data-v-2be4439d] {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  padding: 15px 15px;
}
.yt-cm-lt-col[data-v-2be4439d] {
  padding-right: 7px;
  max-width: 480px;
}
.yt-cm-rt-col[data-v-2be4439d] {
  padding-left: 7px;
}
@media (min-width: 768px) {
.yt-profile-mb-ttl[data-v-2be4439d] {
    display: none;
}
}
@media (min-width: 1280.88px) {
.yt-cm-lt-col[data-v-2be4439d] {
    padding-right: 10px;
}
.yt-cm-rt-col[data-v-2be4439d] {
    padding-left: 10px;
}
}
@media (min-width: 1900px) {
.yt-cm-lt-col[data-v-2be4439d] {
    padding-right: 20px;
}
.yt-cm-rt-col[data-v-2be4439d] {
    padding-left: 20px;
}
}
@media (min-width: 1366px) and (max-width: 1900px) {
.profile-pg-inner-contnet .profile-pg-tabs-name li.active[data-v-2be4439d]::after {
    right: -30px !important;
}
.pg-profile-box .img-upload[data-v-2be4439d] {
    width: 80px;
    height: 80px;
}
.yt-cm-lt-col[data-v-2be4439d] {
    padding-right: 15px;
}
.yt-cm-rt-col[data-v-2be4439d] {
    padding-left: 15px;
}
.yt-cm-ptc-row.yt-btm-inf.row[data-v-2be4439d] {
    margin-top: 30px;
}
.yt-cm-rt-col .profile-pg-inner-wrap[data-v-2be4439d] {
    padding: 40px 30px 35px !important;
}
.profile-name[data-v-2be4439d] {
    font-size: 21px;
    line-height: 1.14;
}
.profile-email[data-v-2be4439d] {
    line-height: 1.13;
    font-size: 16px;
}
.profile-pg-title[data-v-2be4439d] {
    font-size: 26px;
    line-height: 1.12;
}
.profile-pg-inner-wrap.profile-inner-tab-wrap[data-v-2be4439d] {
    padding: 20px;
}
.profile-pg-inner-wrap.profile-inner-tab-content[data-v-2be4439d] {
    padding: 30px;
}
.profile-item-icn[data-v-2be4439d] {
    max-width: 35px;
}
.profile-notiy[data-v-2be4439d] {
    font-size: 12px;
    line-height: 1.17;
    padding: 3px 0px;
    border-radius: 50px;
    width: 20px;
    text-align: center;
    left: 24px;
}
.profile-pg-inner-contnet .profile-pg-tabs-name li[data-v-2be4439d] {
    font-weight: normal;
}
.profile-edit-pass[data-v-2be4439d],
  .profile-edit-btn[data-v-2be4439d] {
    font-size: 16px;
    line-height: 1.13;
}
.profile-pg-breadcrumbs[data-v-2be4439d] {
    margin-bottom: 30px;
}
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.profile-item-wrap .profile-item-name[data-v-2be4439d] {
    font-size: 14px;
    line-height: 1.14;
}
.profile-item-wrap .profile-item-icn[data-v-2be4439d] {
    max-width: 46px;
    margin-right: 17px !important;
}
.profile-pg-inner-wrap[data-v-2be4439d],
  .tab-content .profile-pg-inner-wrap[data-v-2be4439d] {
    padding: 15px !important;
}
.profile-inner-tab-content[data-v-2be4439d] {
    padding: 18px 15px 15px !important;
}
.profile-notiy[data-v-2be4439d] {
    font-size: 12px;
    line-height: 1.17;
}
.yt-cm-lt-col[data-v-2be4439d] {
    max-width: 250px;
    box-sizing: content-box;
}
.img-upload[data-v-2be4439d] {
    width: 43px;
    height: 43px;
}
.yt-profile-img-nm-wrap .img-upload[data-v-2be4439d] {
    width: 90px;
    height: 90px;
}
.yt-profile-img-nm-wrap[data-v-2be4439d] {
    margin-bottom: 20px !important;
}
.img-upload svg[data-v-2be4439d] {
    font-size: 16px;
}
.profile-data-wrap[data-v-2be4439d] {
    max-width: 330px;
}
.profile-data-tag[data-v-2be4439d] {
    font-size: 12px;
    line-height: 1.17;
}
.profile-edit-btn[data-v-2be4439d] {
    padding-top: 14px;
    padding-bottom: 14px;
    background-color: var(--button-primary);
    color: var(--button-text-primary);
    border-color: var(--button-primary);
}
.profile-edit-pass[data-v-2be4439d],
  .profile-edit-btn[data-v-2be4439d] {
    font-size: 14px;
    line-height: 1.14;
    letter-spacing: 0.28px;
}
.profile-name[data-v-2be4439d] {
    font-size: 20px;
    line-height: 1.15;
}
.profile-email[data-v-2be4439d] {
    font-size: 12px;
    line-height: 1.17;
}
.user-profileinfo[data-v-2be4439d] {
    margin-left: 12px !important;
}
.profile-pg-inner-wrap.profile-inner-tab-wrap[data-v-2be4439d] {
    margin-bottom: 10px;
}
.profile-pg-title[data-v-2be4439d] {
    font-size: 24px;
    line-height: 1.17;
    margin-bottom: 14px !important;
}
.yt-cm-ptc-col[data-v-2be4439d] {
    flex: 0 0 100%;
    max-width: 100%;
}
.profile-pg-breadcrumbs[data-v-2be4439d] {
    margin-bottom: 58px;
}
.yt-profile-img-nm-wrap .img-upload svg[data-v-2be4439d] {
    font-size: 34px;
}
.profile-pg-inner-contnet .profile-pg-tabs-name li.active[data-v-2be4439d]::after {
    right: -15px;
}
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
.profile-pg-title[data-v-2be4439d] {
    margin-bottom: 10px !important;
}
.img-upload svg[data-v-2be4439d] {
    font-size: 34px;
}
.profile-pg-title[data-v-2be4439d] {
    font-size: 18px;
    line-height: 1.11;
    padding-left: 8px;
}
.profile-pg-breadcrumbs[data-v-2be4439d] {
    margin-bottom: 10px;
    margin-top: 10px !important;
}
.profile-pg-inner-wrap[data-v-2be4439d],
  .tab-content .profile-pg-inner-wrap[data-v-2be4439d] {
    padding: 20px !important;
}
.yt-profile-mb-ttl.profile-pg-title[data-v-2be4439d] {
    display: block;
}
.yt-cm-row[data-v-2be4439d] {
    display: block;
}
.yt-cm-row .yt-cm-rt-col[data-v-2be4439d],
  .yt-cm-row .yt-cm-lt-col[data-v-2be4439d] {
    width: 100%;
    max-width: 100%;
}
.yt-cm-row.row .yt-cm-rt-col[data-v-2be4439d],
  .yt-cm-row.row .yt-cm-lt-col[data-v-2be4439d] {
    padding-right: 6px;
    padding-left: 6px;
}
.yt-cm-row.row .yt-cm-lt-col[data-v-2be4439d] {
    display: none;
}
.profile-edit-pass[data-v-2be4439d] {
    padding: 0;
    margin-right: auto !important;
}
.profile-edit-btn[data-v-2be4439d] {
    padding-top: 14px;
    padding-bottom: 14px;
    background-color: var(--button-primary);
    color: var(--button-text-primary);
    border-color: var(--button-primary);
}
.profile-edit-pass[data-v-2be4439d],
  .profile-edit-btn[data-v-2be4439d] {
    font-size: 12px;
    line-height: 1.14;
    letter-spacing: 0.28px;
}
.profile-user-name[data-v-2be4439d] {
    font-size: 17px;
    line-height: 1.12;
    margin-bottom: 24px;
}
.profile-data-tag[data-v-2be4439d] {
    font-size: 13px;
    line-height: 1.15;
}
.yt-profile-img-nm-wrap[data-v-2be4439d] {
    margin-bottom: 24px !important;
}
}
