/*
* Global CSS of Help Center
*/

/*
* Breadcrumbs CSS
*/
.hc-breadcrumbs[data-v-a99d211c] {
  color: var(--color-dark-grey);
  font-size: 12px;
  line-height: 1.17;
}
.hc-inner-wrap[data-v-a99d211c] {
  padding: 40px 40px 36px;
}
.hc-inner-contnet .hc-tabs-name li[data-v-a99d211c]:first-child {
  padding-top: 0;
}
.hc-inner-contnet .hc-tabs-name li[data-v-a99d211c]:last-child {
  padding-bottom: 0;
}
.hc-current[data-v-a99d211c] {
  color: var(--color-black);
  font-size: 12px;
  font-weight: 500;
  line-height: 1.17;
}
.hc-beckfrom-ct-page[data-v-a99d211c] {
  margin-top: 50px;
  color: var(--color-black);
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
}
.hc-mb-30[data-v-a99d211c] {
  margin-bottom: 30px;
}
.hc-mt-30[data-v-a99d211c] {
  margin-bottom: 30px;
}
.hc-mb-50[data-v-a99d211c] {
  margin-bottom: 50px;
}
.radius-10[data-v-a99d211c] {
  border-radius: 10px;
}
.list-style-none[data-v-a99d211c] {
  list-style: none;
}
.hcp-back-arrow[data-v-a99d211c] {
  cursor: pointer;
}
.hc-tab-inner-data[data-v-a99d211c]:not(:last-child){
  border-bottom: 1px solid #dbdbdb;
}
/*
* Left Col CSS
*/
.hc-inner-contnet .hc-tabs-name li[data-v-a99d211c] {
  padding: 23.5px 0;
  border-bottom: 1px solid #e2e2e2;
  font-size: 18px;
  line-height: 1.15;
  color: var(--color-black);
  cursor: pointer;
}
.hc-inner-contnet .hc-tabs-name li[data-v-a99d211c]:last-child {
  border: none;
  padding-bottom: 0;
}
.hc-inner-contnet .hc-tabs-name li.active[data-v-a99d211c] {
  font-weight: 500;
  color: var(--color-primary);
}

/*
* Right Col CSS
*/
.hc-tab-main-title[data-v-a99d211c] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-black);
  margin-bottom: 40px;
}
.hc-tab-sub-title[data-v-a99d211c] {
  font-size: 20px;
  font-weight: 500;
  color: var(--color-black);
  line-height: 1.15;
  margin-bottom: 10px;
}
.ht-tab-description[data-v-a99d211c] {
  font-size: 18px;
  line-height: 1.67;
  letter-spacing: 0.18px;
  color: var(--color-dark-grey);
}
.hc-tab-content[data-v-a99d211c] {
  padding: 40px 40px 23px 30px;
}
.yt-cm-lt-col[data-v-a99d211c] {
  padding-right: 7px;
}
.yt-cm-rt-col[data-v-a99d211c] {
  padding-left: 7px;
}
@media (min-width: 768px) {
.hc-inner-contnet.yt-tab-movile-hc[data-v-a99d211c],
  .yt-mb-pg-ttl[data-v-a99d211c] {
    display: none;
}
}
@media (min-width: 1366px) and (max-width: 1900px) {
.hc-inner-wrap[data-v-a99d211c] {
    padding: 30px;
}
.hc-inner-contnet .hc-tabs-name li[data-v-a99d211c] {
    padding: 15px 0;
}
}
@media (min-width: 1280.88px) {
.yt-cm-lt-col[data-v-a99d211c] {
    padding-right: 10px;
}
.yt-cm-rt-col[data-v-a99d211c] {
    padding-left: 10px;
}
.hc-tab-content[data-v-a99d211c] {
    margin-top: 115px;
}
}
@media (min-width: 1400px) {
.hc-tab-content[data-v-a99d211c] {
    padding: 40px 40px 23px;
}
}
@media (min-width: 1900px) {
.yt-cm-lt-col[data-v-a99d211c] {
    padding-right: 20px;
}
.yt-cm-rt-col[data-v-a99d211c] {
    padding-left: 20px;
}
.hc-inner-contnet .hc-tabs-name li[data-v-a99d211c] {
    font-size: 20px;
}
}
@media (max-width: 1279.92px) and (min-width: 768px) {
.yt-cm-lt-col[data-v-a99d211c] {
    max-width: 30%;
}
.yt-cm-rt-col[data-v-a99d211c] {
    flex: 0 0 70%;
    max-width: 70%;
}
.yt-cm-lt-col .hc-inner-wrap[data-v-a99d211c] {
    padding: 33px 15px;
}
.hc-inner-wrap.hc-tab-content[data-v-a99d211c] {
    margin-top: 48px;
    padding: 15px;
}
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.hc-beckfrom-ct-page[data-v-a99d211c] {
    margin: 0 0 20px !important;
}
.hc-tab-main-title[data-v-a99d211c] {
    font-size: 20px;
    line-height: 1.15;
    margin-bottom: 17px;
}
.hc-inner-contnet .hc-tabs-name li[data-v-a99d211c] {
    font-size: 14px;
    line-height: 1.14;
    padding: 20px 0;
}
.hc-tab-sub-title[data-v-a99d211c] {
    font-size: 16px;
    line-height: 1.13;
}
.ht-tab-description[data-v-a99d211c] {
    font-size: 14px;
    line-height: 2.14;
    letter-spacing: 0.14px;
}
.hc-beckfrom-ct-page[data-v-a99d211c] {
    font-size: 24px;
    line-height: 1.17;
}
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
.hc-inner-contnet.yt-desk-hc[data-v-a99d211c] {
    display: none;
}
.yt-cm-lt-col[data-v-a99d211c] {
    padding-right: 6px;
    padding-left: 6px;
}
.hc-beckfrom-ct-page[data-v-a99d211c] {
    margin: 10px 0 !important ;
    font-size: 18px;
    line-height: 1.11;
}
.hc-inner-wrap[data-v-a99d211c] {
    padding: 20px;
}
.hc-inner-contnet .hc-tabs-name li[data-v-a99d211c] {
    line-height: 1.2;
    font-size: 15px;
    padding: 10px 0;
}
.yt-mobile-datapg .yt-cm-rt-col[data-v-a99d211c] {
    display: block;
}
.yt-mb-pg-ttl .hc-beckfrom-ct-page svg[data-v-a99d211c] {
    display: block;
}
.hc-tab-main-title[data-v-a99d211c] {
    font-size: 18px;
    line-height: 1.11;
    position: absolute;
    top: 9px;
    margin: 0 !important;
    left: 35px;
}
.hc-tab-sub-title[data-v-a99d211c] {
    line-height: 1.13;
    font-size: 16px;
}
.ht-tab-description[data-v-a99d211c] {
    font-size: 12px;
    line-height: 1.92;
    letter-spacing: 0.12px;
}
}
