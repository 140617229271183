
#pagination > .pagination {
  justify-content: center;
      background-color: white;
      padding: 15px 0px;
      color: var(--color-primary);
      border: 1px solid var(--color-primary);
      border-radius: 30px;
  }

  #pagination > .pagination > li{
    padding: 0px 10px;
  }
  #pagination > ul.pagination {
    margin-bottom: 0px;
  }
  #pagination > .pagination > .active{
    background-color: var(--color-primary);
    color: white;
    border-radius: 8px;
    padding: 0px 10px;
}