/* div {
    background-color: green;
} */
.bottombar[data-v-a2b86684] {
  /* height: 40px; */
  color: var(--color-black);
  background-color: #f6f8fa;
  font-size: 14px;
  margin: 6px 0px;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
}
.footercontainer[data-v-a2b86684] {
  padding-top: 50px;
  background-color: white;
  padding-bottom: 44px;
}
.social_all a:first-child img[data-v-a2b86684] {
  margin-left: 0;
}
.logoimage[data-v-a2b86684] {
  height: auto;
  width: 145px;
  padding-top: 5px;
}
.data_underlogo[data-v-a2b86684] {
  padding-top: 30px;
  height: 95px;
  width: 330px;
}
.navtitle[data-v-a2b86684] {
  font-size: 15px;
  font-weight: 500;
  color: var(--color-black);
  padding-top: 10px;
}
.navlist[data-v-a2b86684] {
  font-weight: normal;
  text-decoration: none;
  color: var(--color-dark-grey);
  padding-top: 20px;
}
.navitem[data-v-a2b86684] {
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
}
.promotionsimage[data-v-a2b86684] {
  padding-top: 20px;
  padding-bottom: 10px;
  width: 270px;
  height: 150px;
}
.social_logos[data-v-a2b86684] {
  width: 23px;
  margin: 10px;
  cursor: pointer;
  transition: all .55s ease;
}
.social_logos[data-v-a2b86684]:hover {
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
  transition: all .55s ease;
}
.social_all[data-v-a2b86684] {
  /* color: var(--color-primary); */
  color : #dbdbdb;
  padding-left: 36px;
}
.logo-image[data-v-a2b86684]{
  height: 160px;
  width: 100%;
  cursor: pointer;
}
.yt-footer-widget-title[data-v-a2b86684] {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.15;
  color: var(--color-black);
  margin-bottom: 34px;
}
.yt-footler-menu-links .yt-ftr-link[data-v-a2b86684] {
  font-size: 15px;
  line-height: 2;
  text-align: left;
  color: silver;
  cursor: pointer;
}
.yt-ftr-banner[data-v-a2b86684] {
  margin-top: 6px;
}
.yt-text-about[data-v-a2b86684] {
  font-size: 14px;
  line-height: 1.38;
  text-align: left;
  color: var(--color-black);
  padding-left: 36px;
  padding-bottom: 31px;
}
.yt-copyright-text[data-v-a2b86684] {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.13;
  color: var(--color-black);
}
.yt-copyright-text span[data-v-a2b86684]{
  color: var(--color-primary)
}
.emailinput[data-v-a2b86684] {
  width: 100%;
  height: 40px;
}
.emailbutton[data-v-a2b86684] {
  background-color: var(--button-primary);
  color:var(--button-text-primary);
  padding:10px 35px;
  margin-top: 20px;
  border: none;
}
@media (max-width: 1280px) {
.container[data-v-a2b86684],
  .container-lg[data-v-a2b86684],
  .container-md[data-v-a2b86684],
  .container-sm[data-v-a2b86684],
  .container-xl[data-v-a2b86684] {
    max-width: 93.3%;
}
}
/*
* Screen for under 1280 Resolution 
*/
.first-side[data-v-a2b86684]{
  margin-top: -4%;
}
@media (max-width: 1279.92px) {
.yt-col.yt-first-col[data-v-a2b86684] {
    flex: 0 0 100%;
    max-width: 100%;
}
.yt-foote-link-col[data-v-a2b86684],
  .yt-last-col[data-v-a2b86684] {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}
.yt-footer-widget-title[data-v-a2b86684] {
    font-size: 16px;
    line-height: 1.13;
    margin-bottom: 17px;
}
.yt-text-about[data-v-a2b86684] {
    padding-top: 16px;
    padding-bottom: 21px;
    font-size: 14px;
    line-height: 1.57;
}
.social_all[data-v-a2b86684] {
    padding: 0;
    margin-bottom: 17px;
}
.social_all .social_logos[data-v-a2b86684]:first-child {
    margin-left: 0;
}
.yt-footler-menu-links .yt-ftr-link[data-v-a2b86684] {
    font-size: 14px;
    line-height: 1.9;
}
}
/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.88px) {
.yt-last-col[data-v-a2b86684] {
    flex: 0 0 100%;
    max-width: 100%;
}
.yt-foote-link-col[data-v-a2b86684] {
    flex: 0 0 50%;
    max-width: 50%;
}
.yt-footer-widget-title[data-v-a2b86684],
  .yt-footler-menu-links[data-v-a2b86684] {
    margin-bottom: 20px;
}
.yt-text-about[data-v-a2b86684] {
    padding-top: 12px;
    padding-bottom: 20px;
    font-size: 12px;
    line-height: 1.83;
}
.social_all[data-v-a2b86684] {
    padding: 0;
    margin-bottom: 20px;
}
.social_all .social_logos[data-v-a2b86684]:first-child {
    margin-left: 0;
}
.yt-main-footer[data-v-a2b86684] {
    padding-top: 20px;
    padding-bottom: 20px;
}
.yt-footer-widget-title[data-v-a2b86684] {
    font-size: 14px;
    line-height: 1.14;
}
.yt-footler-menu-links .yt-ftr-link[data-v-a2b86684] {
    font-size: 13px;
    line-height: 1.97;
}
}
@media (min-width: 1366px) and (max-width: 1900px) {
.yt-footer-widget-title[data-v-a2b86684] {
    font-size: 18px;
}
.yt-footler-menu-links .yt-ftr-link[data-v-a2b86684] {
    font-size: 16px;
}
}
