.dis-acc-title-bar .modal-title[data-v-70c18683] {
  margin: 0;
  text-align: center;
  flex: 1 1;
  color: var(--color-dark-grey);
}
.dis-acc-body-text[data-v-70c18683] {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}
.dis-acc-title-bar span[data-v-70c18683] {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 !important;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: var(--color-black);
}
.pp-dis-acc-btn-modal[data-v-70c18683] {
  width: 48%;
  margin: 0 !important;
  padding: 0;
  border-radius: 0;
  background: transparent;
  border-color: #fff;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
}
.dis-acc-bottom-bar[data-v-70c18683] {
  position: relative;
  border-top: 2px solid #dbdbdb !important;
}
.pp-dis-acc-btn-modal[data-v-70c18683]:hover {
  border-color: #fff;
}
.pp-dis-acc-btn-light-grey[data-v-70c18683] {
  color: var(--color-dark-grey);
}
.pp-dis-acc-btn-dark-grey[data-v-70c18683] {
  color: var(--color-black);
}
.yt-form-spacer[data-v-70c18683] {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto !important;
  display: block;
  bottom: 10px;
  width: 2px;
  top: 10px;
  background: #dbdbdb;
}

/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.dis-acc-title-bar span[data-v-70c18683] {
    font-size: 18px;
    line-height: 1.11;
}
.dis-acc-body-text[data-v-70c18683] {
    line-height: 1.2;
    font-size: 15px;
    padding-top: 11px !important;
}
.pp-dis-acc-btn-modal[data-v-70c18683] {
    font-size: 15px;
    line-height: 1.2;
}
}
/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
.dis-acc-title-bar span[data-v-70c18683] {
    font-size: 18px;
    line-height: 1.11;
}
.dis-acc-body-text[data-v-70c18683] {
    line-height: 1.13;
    font-size: 16px;
    padding-top: 0 !important;
}
.pp-dis-acc-btn-modal[data-v-70c18683] {
    font-size: 15px;
    line-height: 1.2;
}
}
