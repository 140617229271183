/* .nav-menu{
  padding-left: 10%;
    padding-right: 10%;
} */
.new-nav[data-v-fb0c23ec] {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#shopMenu[data-v-fb0c23ec] {
  background-color: #6cb83a;
  border: none;
  transition: 0.5s ease;
}
#shopMenu2[data-v-fb0c23ec]{
  background-color: var(--color-primary);
  border: none;
  transition: 0.5s ease;
}
#shopMenu[data-v-fb0c23ec]:hover {
  background-color: var(--color-primary);
}
.yt-main-header[data-v-fb0c23ec] {
  box-shadow: 0px 6px 35px -12px rgba(0, 0, 0, 0.2);
}
.yt-main-header .topbar[data-v-fb0c23ec] {
  color: var(--color-black);
  background-color: #f6f8fa;
  padding: 21px 0 17px;
}
.yt-main-header .logocontainer[data-v-fb0c23ec] {
  background-color: white;
}
.yt-header-top-message[data-v-fb0c23ec] {
  font-size: 20px;
  line-height: 1.15;
  color: var(--color-black);
}
.yt-main-header .supportbox[data-v-fb0c23ec] {
  height: 39px;
  /* width: 220px; */
  display: flex;
  /* justify-content: center; */
  /* align-items: center;*/
}
.yt-main-header .support[data-v-fb0c23ec] {
  display: flex;
  justify-content: center;
  align-items: center;
}
.yt-main-header .supportimage[data-v-fb0c23ec] {
  height: auto;
  width: 38.5px;
  margin-right: 10px;
  color: var(--color-primary);
}
.yt-main-header .supportnumber[data-v-fb0c23ec] {
  display: flex;
  flex-direction: column;
}
.yt-main-header .supportnumber .title[data-v-fb0c23ec] {
  font-size: 13px;
  line-height: 1.2;
  font-weight: normal;
  color: var(--color-dark-grey);
  padding-bottom: 5px;
  margin-top: -5px;
}
.yt-main-header .supportnumber .number[data-v-fb0c23ec] {
  font-weight: 500;
  line-height: 1.15;
  font-size: 14px;
  color: var(--color-black);
}
.yt-main-header .searchcontainer[data-v-fb0c23ec] {
  display: flex;
  align-items: center;
  height: 100%;
}
.yt-main-header .searchinput[data-v-fb0c23ec] {
  border-radius: 6px;
  border: solid 1px #b2b3b3;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 56px;
  line-height: 1.15;
  color: var(--color-black);
  font-size: 1vw;
  font-weight: 500;
  padding-top: 12px;
  padding-bottom: 12px;
}
.searchinput[data-v-fb0c23ec]:focus-visible{
  outline-color: #ff5e00;
}
.yt-main-header .searchinput[data-v-fb0c23ec]::-webkit-input-placeholder {
  color: #b2b3b3;
}
.yt-main-header .searchinput[data-v-fb0c23ec]::placeholder {
  color: #b2b3b3;
}
.yt-main-header .searchicon[data-v-fb0c23ec] {
  height: auto;
  width: 60px;
  padding: 20px;
  position: absolute;
  left: 18px;
}
.yt-main-header .cartcontainer[data-v-fb0c23ec] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* height: 75%; */
}
.yt-main-header .carticon[data-v-fb0c23ec] {
  height: auto;
  width: 26px;
  margin-right: 0px;
  cursor: pointer;
  font-size: 10px;
  color: #596271;
}
.yt-main-header .favoriteicon[data-v-fb0c23ec] {
  height: auto;
  width: 26px;
  margin-right: 0px;
  cursor: pointer;
  font-size: 10px;
  color: darkslategray;
}
.yt-main-header .loginbutton[data-v-fb0c23ec] {
  width: 140px;
  height: 36px;
  border-radius: 6px;
  background-color: #ff5e00;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  transition: .5s ease-out;
}
.loginbutton[data-v-fb0c23ec]:hover {
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
  background-color: white;
}
.yt-main-header .menucontainer[data-v-fb0c23ec] {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: 2px;
  background-color: white;
}
.yt-main-header .menuicon[data-v-fb0c23ec] {
  height: auto;
  width: 16px;
  position: absolute;
  left: 20px;
  background-color: white;
}
.yt-main-header nav.navbar[data-v-fb0c23ec] {
  padding: 0px;
}
.yt-main-header ul.navbar-nav[data-v-fb0c23ec] {
  width: 100%;
  justify-content: space-around;
  background-color: white;
}
.yt-main-header .nav-link[data-v-fb0c23ec] {
  color: var(--menu-title-text) !important;
  border-bottom: 3px solid white;
}
.yt-main-header .nav-link.active[data-v-fb0c23ec] {
  color: var(--menu-title-text-active) !important;
  text-decoration: none;
  font-weight: bold;
  text-underline-position: under;
  border-bottom: 3px solid var(--color-primary);
}
.yt-main-header .cm-drop-down-wrap[data-v-fb0c23ec] {
  position: static;
}
.yt-main-header .cm-big-drop-menu[data-v-fb0c23ec] {
  width: 144%;
  padding: 28px 44px;
  margin-left: 52%;
  left: 0px;
  right: 0px;
  top: 80%;
  margin-right: auto;
  cursor: pointer;
  position: absolute;
}
.myHeader1[data-v-fb0c23ec]{
  position: relative;
}
.myHeader2[data-v-fb0c23ec] {
  position: fixed;
  -webkit-transform: translateY(10);
          transform: translateY(10);
  transition: -webkit-transform 6s ease;
  transition: transform 6s ease;
  transition: transform 6s ease, -webkit-transform 6s ease;
  background-color: #f6f8fa;
  width: 100%;
  z-index: 111;
}
.categoryimage[data-v-fb0c23ec] {
  width: 45px;
  height: 45px;
  object-fit: cover;
  border-radius: 58px;
  object-position: center;
}
.left-menu-item[data-v-fb0c23ec]:hover {
  color: var(--color-primary);
}
.yt-main-header .cm-big-menu-head .dropdown-divider[data-v-fb0c23ec] {
  border-top: 1px solid var(--color-dark-grey);
}
.yt-main-header .cm-big-menu-inner .dropdown-item[data-v-fb0c23ec] {
  font-size: 16px;
  line-height: 1.13;
  text-align: left;
  color: var(--color-dark-grey);
  padding: 8px 10px;
  margin-bottom: 5px;
  background: transparent !important;
  box-shadow: none !important;
  outline: none !important;
}
.yt-main-header .cm-big-menu-inner .cm-big-menu-head button[data-v-fb0c23ec] {
  font-size: 22px;
  font-weight: 500;
  line-height: 1.14;
  color: var(--color-black);
}
.yt-main-header .head-dropdown-arrow-icn[data-v-fb0c23ec] {
  font-size: 12px;
  margin-left: 4px;
}
.yt-main-header li.cm-drop-down-wrap.dropdown.show > a[data-v-fb0c23ec] {
  color: var(--menu-title-text-active) !important;
  font-weight: 600;
}
.yt-main-header .header-search-field-wrap[data-v-fb0c23ec] {
  position: relative;
}
.yt-main-header .yt-recent-search-wrap[data-v-fb0c23ec] {
  position: absolute;
  top: 55px;
  left: 0;
  right: 0;
  z-index: 50;
}
.yt-main-header .recent-search-tag-name[data-v-fb0c23ec] {
  font-size: 15px;
  line-height: 1.2;
  letter-spacing: normal;
  color: var(--color-dark-grey);
}
.yt-main-header .recent-search-list-wrap .active[data-v-fb0c23ec] {
  background: transparent;
  color: inherit;
}
.yt-main-header .yt-recent-search-wrap[data-v-fb0c23ec] {
  border-radius: 10px;
  box-shadow: 0 3px 40px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  padding: 32px 20px 27px;
  max-height: 400px;
  overflow-y: scroll;
}
.yt-main-header .yt-no-search-found-wrap[data-v-fb0c23ec] {
  padding: 54px 30px 46px;
}
.yt-main-header .recent-search-list-wrap .list-group-item[data-v-fb0c23ec] {
  border-bottom: 2px solid #e2e2e2;
  margin: 0;
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-black);
  padding: 15px 0 !important;
}
.yt-main-header .recent-search-list-wrap .list-group-item[data-v-fb0c23ec]:first-child {
  padding-top: 0 !important;
}
.yt-main-header .recent-search-list-wrap .list-group-item[data-v-fb0c23ec]:last-child {
  padding-bottom: 0 !important;
}
.yt-main-header .search-no-ttl[data-v-fb0c23ec] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-black);
  margin-top: 35px;
  margin-bottom: 15px;
}
.yt-main-header .search-no-sub-ttl[data-v-fb0c23ec] {
  font-size: 16px;
  line-height: 1.13;
  text-align: center;
  color: var(--color-dark-grey);
}
.yt-main-header .yt-main-menu .nav-item[data-v-fb0c23ec] {
  cursor: pointer;
}
.yt-main-header .logoimage[data-v-fb0c23ec] {
  width: 100%;
  max-width: 112px;
}
.yt-head-login-btn[data-v-fb0c23ec] {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  letter-spacing: 0.36px;
  text-align: center;
  color: #ffffff;
  background-color: var(--color-dark-black);
  padding: 15px 26px;
}
.user_Name[data-v-fb0c23ec] {
  cursor: pointer;
}
.left-menu-items-container[data-v-fb0c23ec] {
  overflow-y: scroll;
  max-height: 20px;
  min-height: 365px;
}
.left-menu-item[data-v-fb0c23ec] {
  font-weight: 500;
  font-size: 18px;
  padding-top: 15px;
  padding-bottom: 10px;
  width: 235px;
}
.left-menu-item.active[data-v-fb0c23ec] {
  color: var(--color-primary);
}
.right-menu-item.active[data-v-fb0c23ec] {
  color: var(--color-primary);
}

/* width */
.left-menu-items-container[data-v-fb0c23ec]::-webkit-scrollbar {
  width: 5px;
  min-height: 10px;
  max-height: 10px;
}

/* Track */
.left-menu-items-container[data-v-fb0c23ec]::-webkit-scrollbar-track {
  background: #a3a3a3;
  border-left: 2px solid white;
    border-right: 2px solid white;
}

/* Handle */
.left-menu-items-container[data-v-fb0c23ec]::-webkit-scrollbar-thumb {
  background-color: var(--color-primary);
}

/* Handle on hover */
.left-menu-items-container[data-v-fb0c23ec]::-webkit-scrollbar-thumb:hover {
  background: var(--color-primary);
}

/* width */
.right-menu-items-container[data-v-fb0c23ec]::-webkit-scrollbar {
  width: 5px;
  min-height: 10px;
  max-height: 10px;
}

/* Track */
.right-menu-items-container[data-v-fb0c23ec]::-webkit-scrollbar-track {
  background: #a3a3a3;
  border-left: 2px solid white;
    border-right: 2px solid white;
}

/* Handle */
.right-menu-items-container[data-v-fb0c23ec]::-webkit-scrollbar-thumb {
  background-color: var(--color-primary);
}

/* Handle on hover */
.right-menu-items-container[data-v-fb0c23ec]::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-primary);
}
.right-menu-items-container[data-v-fb0c23ec] {
  overflow-y: auto;
  max-height: 300px;
  min-height: 300px;
}
.right-menu-item[data-v-fb0c23ec] {
  font-size: 18px;
  color: black  ;
  padding-top: 20px;
  font-weight: 300;
  padding-bottom: 10px;
}
.right-menu-item[data-v-fb0c23ec]:hover {
  color: var(--color-primary);
}
.headerIcon[data-v-fb0c23ec]{
  margin-top: -3%;
  margin-left: -4%;
}
.yt-mobile-side-nav-wrap[data-v-fb0c23ec] {
  display: none;
}
.yt-main-header .cartcontainer span.w3-green[data-v-fb0c23ec] {
  text-align: center;
  padding: 5px 6px !important;
  border-radius: 50px !important;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  min-width: 20px !important;
  display: inline-block;
}
@media (min-width: 1900px) {
.yt-main-header .cm-big-drop-menu[data-v-fb0c23ec] {
    max-width: 1421px;
}
.yt-main-header
    .cm-big-drop-menu
    .yt-main-header
    .cm-big-menu-inner
    .dropdown-item[data-v-fb0c23ec] {
    font-size: 20px;
    line-height: 1.15;
}
.yt-main-header .cm-big-menu-inner .cm-big-menu-head button[data-v-fb0c23ec] {
    font-size: 28px;
    line-height: 1.14;
}
.yt-main-header .searchinput[data-v-fb0c23ec] {
    padding-top: 24px;
    padding-bottom: 23px;
}
.yt-main-header .logoimage[data-v-fb0c23ec] {
    max-width: 297px;
}
}
@media only screen and (min-width: 992px) {
.yt-main-header .order-sm-1[data-v-fb0c23ec] {
    order: 0;
}
.yt-main-header .searchicon[data-v-fb0c23ec] {
    left: 0px;
}
.yt-main-header ul.navbar-nav[data-v-fb0c23ec] {
    padding: 0px 0px 0px;
}
}
@media only screen and (min-width: 768px) {
.yt-only-mobile-vw[data-v-fb0c23ec] {
    display: none;
}
.yt-only-mobile-vw.tr .yt-mobile-side-nav-wrap[data-v-fb0c23ec] {
    display: none;
}
}
@media only screen and (max-width: 992px) {
.yt-main-header .order-sm-1[data-v-fb0c23ec] {
    order: 1;
}
.yt-main-header .searchicon[data-v-fb0c23ec] {
    left: 32px;
}
.yt-main-header .searchinput[data-v-fb0c23ec] {
    font-size: 1.9vw;
}
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.88px) {
.userbox span.uname[data-v-fb0c23ec],
  .userbox svg[data-v-fb0c23ec] {
    display: none;
}
.yt-main-header .cm-big-drop-menu[data-v-fb0c23ec]{
    margin: 0;
    position: inherit;
}
.yt-cart-icn-mainwp[data-v-fb0c23ec] {
    position: relative;
    margin-right: 15px;
}
.headerIcon[data-v-fb0c23ec]{
    margin-top: -34% !important;
}
.yt-cart-icn-mainwp span.w3-green[data-v-fb0c23ec] {
    position: absolute !important;
    right: 0 !important;
}
.yt-head-col[data-v-fb0c23ec] {
    flex: 0 0 50%;
    max-width: 50%;
}
.new-nav[data-v-fb0c23ec] {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.AAAA[data-v-fb0c23ec]{
    display: none;
}
.yt-main-header .logocontainer[data-v-fb0c23ec]{
    padding: 0  !important;
}
.yt-main-header .yt-head-hide-srch-col[data-v-fb0c23ec],
  .yt-main-header .topbar[data-v-fb0c23ec] {
    display: none;
}
.yt-main-header .logocontainer[data-v-fb0c23ec] {
    padding-top: 15px;
    padding-bottom: 15px;
}
.yt-main-header .supportbox[data-v-fb0c23ec] {
    display: none;
}
.yt-main-header .logoimage[data-v-fb0c23ec] {
    max-width: 91px;
}
.yt-main-header .logobox[data-v-fb0c23ec] {
    margin-left: 38px;
}
.yt-main-header .loginbutton span[data-v-fb0c23ec] {
    display: none;
}
.yt-main-header .loginbutton span.yt-head-lgn-btn[data-v-fb0c23ec] {
    display: block !important;
}
.yt-main-header .loginbutton span[data-v-fb0c23ec] {
    font-size: 14px;
    line-height: 1.14;
    letter-spacing: 0.28px;
    color: darkslategray;
}
.yt-main-header .loginbutton[data-v-fb0c23ec] {
    background: transparent;
    width: auto;
    height: auto;
    border-radius: 0;
}
.yt-main-header .carticon[data-v-fb0c23ec] {
    margin-right: 11px;
    width: 21px;
}
.yt-main-header .favoriteicon[data-v-fb0c23ec] {
    margin-right: 11px;
    width: 21px;
}
.yt-main-header .yt-head-seachicn-mb[data-v-fb0c23ec] {
    display: block !important;
    position: inherit;
    margin-right: 11px;
}
.yt-head-mb-search-panel[data-v-fb0c23ec] {
    display: block !important;
}
.yt-only-mobile-vw.tr .yt-mobile-side-nav-wrap[data-v-fb0c23ec] {
    display: block;
}
.yt-mobile-side-nav-wrap[data-v-fb0c23ec] {
    position: fixed;
    background-color: rgb(62 69 79 / 0.8);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 500;
    overflow: hidden;
    height: 100%;
    width: 100%;
}
.yt-mobile-side-inner-content[data-v-fb0c23ec] {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: 550;
    max-width: 221px;
    overflow: auto;
    height: 100%;
    width: 100%;
}
.yt-mb-user-profile[data-v-fb0c23ec] {
    padding: 20px;
    background-color: var(--color-primary);
    color: #fff;
}
.yt-mobile-side-inner-content .yt-inner-cnt-logo[data-v-fb0c23ec] {
    padding: 15px 20px 20px;
}
.yt-mobile-nav-content[data-v-fb0c23ec] {
    padding: 17px 20px;
    border-bottom: solid 1px #dbdbdb;
}
.yt-mobile-nav-content[data-v-fb0c23ec]:last-child {
    border: none;
}
.yt-mb-user-profile .yt-head-name[data-v-fb0c23ec] {
    font-size: 16px;
    line-height: 1.13;
}
.yt-mb-user-profile .yt-head-email[data-v-fb0c23ec] {
    font-size: 8px;
    line-height: 1.13;
}
.yt-mobile-nav-content li:last-child a[data-v-fb0c23ec] {
    padding-bottom: 0;
}
.yt-mb-user-prfl-lks li:first-child a[data-v-fb0c23ec] {
    padding-top: 0;
}
.yt-mb-user-prfl-lks[data-v-fb0c23ec] {
    border-top: solid 1px #dbdbdb;
    padding: 20px;
}
.yt-main-header .yt-mobile-nav-content .cm-big-drop-menu[data-v-fb0c23ec] {
    padding: 0;
    border: none;
    width: 105%;
}
.yt-main-header
    .yt-mobile-nav-content
    li.cm-drop-down-wrap.dropdown.show
    > a[data-v-fb0c23ec] {
    border-bottom: none;
}
.yt-main-header .yt-mobile-nav-content .cm-big-menu-head .dropdown-divider[data-v-fb0c23ec] {
    border-bottom: solid 1px #dbdbdb;
    border-top: none;
}
.yt-main-header .yt-mobile-nav-content .cm-big-menu-inner .dropdown-item[data-v-fb0c23ec] {
    font-size: 13px;
    line-height: 1.1;
}
.yt-main-header .yt-mobile-nav-content .cm-big-menu-head .dropdown-item[data-v-fb0c23ec] {
    font-size: 15px;
    line-height: 1.17;
    color: var(--color-black);
}
.yt-mb-user-profile .yt-mb-nav-login-btn[data-v-fb0c23ec] {
    font-size: 14px;
    line-height: 1.14;
    letter-spacing: 0.28px;
    font-weight: 500;
}
.yt-mb-innet-search[data-v-fb0c23ec] {
    padding: 14px 15px 0;
}
.yt-main-header .yt-mb-innet-search .searchicon[data-v-fb0c23ec] {
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    max-width: 16px;
    left: 12px;
    padding: 0px;
}
.yt-main-header .yt-mb-innet-search .searchinput[data-v-fb0c23ec] {
    padding: 10px 10px 10px 38px !important;
    font-size: 12px;
    line-height: 1.17;
}
.yt-main-header .yt-mb-innet-search .searchinput[data-v-fb0c23ec]::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-size: 12px;
    line-height: 1.17;
}
.yt-main-header .yt-mb-innet-search .searchinput[data-v-fb0c23ec]::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 12px;
    line-height: 1.17;
}
.yt-main-header .yt-mb-innet-search .searchinput[data-v-fb0c23ec]:-ms-input-placeholder {
    /* IE 10+ */
    font-size: 12px;
    line-height: 1.17;
}
.yt-main-header .yt-mb-innet-search .searchinput[data-v-fb0c23ec]:-moz-placeholder {
    /* Firefox 18- */
    font-size: 12px;
    line-height: 1.17;
}
.yt-mb-header-search-bar-wrap input[data-v-fb0c23ec] {
    border: none !important;
    padding: 12px 0;
    outline: none !important;
    width: 100%;
    font-size: 4vw;
    font-weight: 500;

    line-height: 1.11;
}
.yt-main-header .yt-recent-search-wrap[data-v-fb0c23ec] {
    background-color: #f6f8fa;
    padding: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 500;
    margin: 0 !important;
}
.yt-main-header .yt-recent-search-wrap > .d-flex[data-v-fb0c23ec] {
    margin: 13px 18px !important;
}
.yt-main-header .yt-mb-header-search-bar-wrap[data-v-fb0c23ec] {
    padding: 17px 20px;
    background: #fff;
}
.yt-main-header .recent-search-list-wrap[data-v-fb0c23ec] {
    padding: 0 20px;
    background: #fff;
}
.yt-main-header .recent-search-list-wrap .list-group-item[data-v-fb0c23ec],
  .yt-main-header .recent-search-list-wrap .list-group-item[data-v-fb0c23ec]:first-child,
  .yt-main-header .recent-search-list-wrap .list-group-item[data-v-fb0c23ec]:last-child {
    padding: 15px 0 !important;
    font-size: 15px;
    line-height: 1.2;
}
.yt-main-header .recent-search-tag-name[data-v-fb0c23ec] {
    font-size: 15px;
    line-height: 1.2;
}
.yt-header-search-close-icn[data-v-fb0c23ec] {
    position: absolute;
    top: 12px;
    right: 23px;
    display: none;
}
.yt-header-search-close-icn.d-none[data-v-fb0c23ec] {
    display: block !important;
    max-width: 20px;
}
.yt-mb-header-search-bar-wrap input[data-v-fb0c23ec]::-webkit-input-placeholder {
    color: #dbdbdb;
    opacity: 1; /* Firefox */
}
.yt-mb-header-search-bar-wrap input[data-v-fb0c23ec]::placeholder {
    color: #dbdbdb;
    opacity: 1; /* Firefox */
}
.yt-mb-header-search-bar-wrap input[data-v-fb0c23ec]:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #dbdbdb;
}
.yt-mb-header-search-bar-wrap input[data-v-fb0c23ec]::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #dbdbdb;
}
.yt-no-srch-found[data-v-fb0c23ec] {
    margin-top: 58px;
}
.yt-main-header .search-no-ttl[data-v-fb0c23ec] {
    font-size: 17px;
    line-height: 1.12;
    margin-bottom: 8px;
}
.yt-main-header .search-no-sub-ttl[data-v-fb0c23ec] {
    font-size: 15px;
    line-height: 1.2;
    max-width: 235px;
    margin-left: auto;
    margin-right: auto;
}
}
.usericon[data-v-fb0c23ec] {
  width: 42px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid silver;
}
.uname[data-v-fb0c23ec] {
  margin-left: 10px;
  color: gray;
}
.userbox[data-v-fb0c23ec] {
  cursor: pointer;
}
@media (max-width:1504px) {
.yt-main-header .cm-big-drop-menu[data-v-fb0c23ec]{
    width: 200%;
}
}
@media (min-width: 1366px) and (max-width: 1900px) {
.yt-main-header .cm-big-drop-menu[data-v-fb0c23ec] {
    padding: 40px;
}
.yt-main-header .supportnumber .title[data-v-fb0c23ec] {
    font-size: 14px;
}
.yt-main-header .supportnumber .number[data-v-fb0c23ec] {
    font-size: 14px;
}
.yt-main-header .searchinput[data-v-fb0c23ec] {
    font-size: 1vw;
}
.yt-main-header .loginbutton[data-v-fb0c23ec] {
    font-size: 16px;
}
.yt-main-header .yt-main-menu .nav-item[data-v-fb0c23ec] {
    font-size: 18px;
}
.yt-main-header .cm-big-menu-inner .cm-big-menu-head button[data-v-fb0c23ec] {
    font-size: 20px;
}
}
