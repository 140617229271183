/*
* Home Page CSS
*/
/*
* Global CSS
*/
.yt-component-wrapper[data-v-9862d71b]{
    position: relative;
}


/*End*/
.deals[data-v-9862d71b] {
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 17px;
    font-weight: 500;
}
.products[data-v-9862d71b] {
    background-color: white;
    padding: 30px;
    margin-bottom: 20px;
}
.more[data-v-9862d71b] {
    float: right;
    font-size: 13px;
    cursor: pointer;
}
.fullscreen[data-v-9862d71b] {
    width: 100%;
}
.oneintwo[data-v-9862d71b] {
    margin-top: 20px;
    margin-bottom: 20px;
}
.shopnowbutton[data-v-9862d71b] {
    width: 140px;
    background-color: black;
    color: white;
    padding: 10px;
    border-radius: 5px;
    height: 40px;
    text-align: center;
    cursor: pointer;
    margin-top: 10px;
}
.savetext[data-v-9862d71b] {
    color: black;
    font-weight: 600;
    font-size: 20px;
}
.textblock1[data-v-9862d71b] {
    margin-top: -150px;
    margin-left: 80px;
}
.welcometext[data-v-9862d71b] {
    color: black;
    font-weight: 600;
    font-size: 40px;
}
.textblock2[data-v-9862d71b] {
    margin-top: -350px;
    margin-left: 80px;
}
.headtop[data-v-9862d71b] {
    color: white;
    font-weight: 200px;
}
.uptooff[data-v-9862d71b] {
    color: white;
    font-weight: 600;
    font-size: 60px;
}
.textblock3[data-v-9862d71b] {
    margin-top: -300px;
    margin-left: 350px;
}
.logohead1[data-v-9862d71b] {
    margin-left: 30px;
    font-weight: 500px;
    margin-top: -60px;
    font-size: 20px;
    color: white;
}
.shopnowgoldenbutton[data-v-9862d71b] {
    width: 100px;
    background-color: var(--color-primary);
    color: white;
    font-weight: 400px;
    padding: 10px;
    border-radius: 5px;
    height: 40px;
    text-align: center;
    cursor: pointer;
    margin-top: -70px;
    float: right;
    margin-right: 10px;
}
.shopnowgoldenbutton1[data-v-9862d71b] {
    width: 100px;
    background-color: var(--color-primary);
    color: white;
    font-weight: 400px;
    padding: 10px;
    border-radius: 5px;
    height: 40px;
    text-align: center;
    cursor: pointer;
    float: left;
    margin-top: 20px;
}
.dontmiss[data-v-9862d71b] {
    font-size: 30px;
}
.offthirty[data-v-9862d71b] {
    font-size: 70px;
    font-weight: 600;
}
.textbox4[data-v-9862d71b] {
    margin-left: 120px;
    margin-top: -500px;
}
.hurryup[data-v-9862d71b] {
    font-size: 30px;
    color: white;
    font-weight: 600px;
}
.dailydeal[data-v-9862d71b] {
    font-size: 30px;
    color: white;
    font-weight: 600px;
}
.lowprice[data-v-9862d71b] {
    color: white;
}
.textbox5[data-v-9862d71b] {
    margin-left: 50px;
    margin-top: -200px;
}
.happytimes[data-v-9862d71b] {
    font-size: 30px;
    color: white;
    font-weight: 600px;
}
.flat[data-v-9862d71b] {
    font-size: 40px;
    color: white;
    font-weight: 700px;
}
.textbox7[data-v-9862d71b] {
    margin-top: -230px;
    margin-left: auto;
    margin-right: auto;
}
.stores[data-v-9862d71b] {
    width: 30%;
    margin-top: 70px;
    cursor: pointer;
}
.playbuttons[data-v-9862d71b] {
    margin-left: 190px;
    margin-right: auto;
}
.download[data-v-9862d71b] {
    font-size: 40px;
    font-weight: 400;
    color: black;
    margin-left: 100px;
}
.imglast[data-v-9862d71b] {
    width: 100%;
    height: 100%;
}


/* for mobile */
@media only screen and (max-width: 992px) {
.shopnowbutton[data-v-9862d71b] {
        width: 100px;
        padding: 5px;
        height: 30px;
}
.shopnowgoldenbutton1[data-v-9862d71b] {
        width: 100px;
        padding: 5px;
        height: 30px;
}
.savetext[data-v-9862d71b] {
        font-size: 15px;
}
.textblock1[data-v-9862d71b] {
        margin-top: -90px;
        margin-left: 50px;
}
.welcometext[data-v-9862d71b] {
        color: black;
        font-weight: 600;
        font-size: 20px;
}
.textblock2[data-v-9862d71b] {
        margin-top: -200px;
        margin-left: 40px;
        width: 50%;
}
.headtop[data-v-9862d71b] {
        font-weight: 200px;
}
.uptooff[data-v-9862d71b] {
        color: white;
        font-weight: 600;
        font-size: 30px;
}
.textblock3[data-v-9862d71b] {
        margin-top: -250px;
        margin-left: 200px;
        width: 50%;
}
.three[data-v-9862d71b] {
        padding-bottom: 10px;
}
.logohead1[data-v-9862d71b] {
        width: 100%;
}
.hurryup[data-v-9862d71b] {
        font-size: 20px;
        color: white;
        font-weight: 600px;
}
.dailydeal[data-v-9862d71b] {
        font-size: 15px;
        color: white;
        font-weight: 600px;
}
.lowprice[data-v-9862d71b] {
        color: white;
        font-size: 10px;
}
.textbox5[data-v-9862d71b] {
        margin-left: 30px;
        margin-top: -160px;
        width: 50%;
}
.happytimes[data-v-9862d71b] {
        font-size: 20px;
        color: white;
        font-weight: 600px;
}
.flat[data-v-9862d71b] {
        font-size: 25px;
        color: white;
        font-weight: 700px;
}
.stores[data-v-9862d71b] {
        width: 30%;
        margin-top: 0px;
}
.playbuttons[data-v-9862d71b] {
        margin-left: 120px;
        margin-right: auto;
}
.download[data-v-9862d71b] {
        font-size: 15px;
        font-weight: 400;
        color: black;
        margin-left: 120px;
}
.textbox7[data-v-9862d71b] {
        margin-top: -60px;
        margin-left: auto;
        margin-right: auto;
}
.imglast[data-v-9862d71b] {
        width: 100%;
        height: 100%;
}
.dontmiss[data-v-9862d71b] {
        font-size: 15px;
}
.offthirty[data-v-9862d71b] {
        font-size: 25px;
        font-weight: 600;
}
.textbox4[data-v-9862d71b] {
        margin-left: 40px;
        margin-top: -150px;
        width: 50%;
}
}

/* for desktop */
@media only screen and (min-width: 992px) {}
.featurebarcontainer[data-v-9862d71b]{
    margin:40px 0px;
}