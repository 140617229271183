.prodimage[data-v-d52c1c26] {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
  cursor: pointer;
}
.name[data-v-d52c1c26] {
  margin-top: -30px;
  font-weight: 500;
  margin-left: auto;
  margin-right: auto;
  color: white;
}
.hp-collection-slider[data-v-d52c1c26] {
  margin: 60px 0;
}
.yt-slider-left[data-v-d52c1c26],
.yt-slider-right[data-v-d52c1c26] {
  position: absolute;
  font-size: 35px;
  cursor: pointer;
  color: var(--color-primary);
}
.yt-comonent-link[data-v-d52c1c26] {
  margin-right: 8%;
}
.collection-card-slider > div > div[data-v-d52c1c26] {
  margin: 0 !important;
}
.yt-collection-item[data-v-d52c1c26] {
  background: #fff;
  width: 100%;
  margin: 0 10px;
  position: relative;
  cursor: pointer;
}
.yt-collection-item .content-overlay[data-v-d52c1c26]{
  background: #3e454fcc;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  transition: all 0.4s ease-in-out 0s;
  border-radius: 5px;
}
.yt-collection-item:hover .content-overlay[data-v-d52c1c26]{
  opacity: 1;
}
.content-details[data-v-d52c1c26] {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: all 0.3s ease-in-out 0s;
}
.yt-collection-item:hover .content-details[data-v-d52c1c26]{
  top: 50%;
  left: 50%;
  opacity: 1;
}
.yt-collection-item:hover .yt-collection-title[data-v-d52c1c26]{
  opacity: 0;
}
.content-details[data-v-d52c1c26] {
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.15em;
  margin-bottom: 0.5em;
  text-transform: uppercase;
}
.fadeIn-bottom[data-v-d52c1c26] {
  top: 80%;
}
.yt-collection-title[data-v-d52c1c26] {
  font-size: 20px;
  text-align: center;
  position: absolute;
  bottom: 15px;
  left: 15px;
  right: 15px;
  padding: 15px 0;
  line-height: 0.95;
  color: black;
  background-color: white;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.yt-collection-component[data-v-d52c1c26] {
  position: relative;
  border-radius: 10px;
}
.yt-collection-component .rec-item-wrapper[data-v-d52c1c26] {
  outline: none !important;
  box-shadow: none;
}
.yt-component-more[data-v-d52c1c26] {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.13;
  text-align: right;
  border-radius: 0;
  color: var(--color-dark-grey);
}
.yt-comonent-top-title[data-v-d52c1c26] {
  font-size: 35px;
  font-weight: 500;
  line-height: 1.13;
  text-align: left;
  color: var(--color-default);
}
.yt-collection-item img[data-v-d52c1c26]{
  border-radius: 5px;
}
/* 
.yt-produstslider-info {
  padding-bottom: 30px;
}
 */
@media (min-width: 1900px) {
.yt-collection-item[data-v-d52c1c26] {
    margin: 0 15px;
}
}
@media (min-width: 1280px) {
.yt-collection-item img[data-v-d52c1c26] {
    height: 266px;
    width: 370px;
    object-fit: cover;
    object-position: top;
}
}
@media (min-width: 1281px) {
.yt-slider-left[data-v-d52c1c26],
  .yt-slider-right[data-v-d52c1c26] {
    top: -7%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}
.yt-slider-left[data-v-d52c1c26] {
    right: 4%;
}
.yt-slider-right[data-v-d52c1c26] {
    right: 1%;
}
}
@media (min-width: 1920px) {
.yt-collection-item img[data-v-d52c1c26] {
    height: 270px;
    width: 320px;
    object-fit: cover;
    object-position: top;
}
}
@media (max-width: 1280px) {
.yt-slider-left[data-v-d52c1c26],
  .yt-slider-right[data-v-d52c1c26] {
    top: -56px;
}
.yt-slider-left[data-v-d52c1c26] {
    right: 43px;
}
.yt-slider-right[data-v-d52c1c26] {
    right: 0;
}
.yt-comonent-link[data-v-d52c1c26] {
    margin-right: 8%;
}
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.yt-produstslider-info[data-v-d52c1c26] {
    padding-bottom: 15px;
}
.yt-comonent-top-title[data-v-d52c1c26] {
    font-size: 25px;
    line-height: 1.14;
}
.yt-component-more[data-v-d52c1c26] {
    font-size: 18px;
    line-height: 1.14;
}
.yt-slider-left[data-v-d52c1c26],
  .yt-slider-right[data-v-d52c1c26] {
    font-size: 21px;
    top: -40px;
}
.yt-slider-left[data-v-d52c1c26] {
    right: 42px;
}
.yt-slider-right[data-v-d52c1c26] {
    right: 20px;
}
.yt-collection-title[data-v-d52c1c26] {
    font-size: 14px;
    line-height: 0.71;
    padding-top: 9px;
    padding-bottom: 13px;
    left: 0;
    right: 0;
    bottom: 0;
}
.yt-collection-item[data-v-d52c1c26] {
    padding: 0 !important;
    border: none !important;
}
.yt-comonent-link[data-v-d52c1c26] {
    margin-right: 8%;
}
.yt-collection-component[data-v-d52c1c26] {
    margin-left: -20px;
    margin-right: -20px;
    border-radius: 0 !important;
}
section.hp-collection-slider[data-v-d52c1c26] {
    margin: 20px 0 !important;
}
.yt-collection-item img[data-v-d52c1c26] {
    height: 223px;
    width: 167px;
    object-fit: cover;
    object-position: top;
}
}
/*
* Screen for 768 Resolution 
*/
@media (max-width: 767.98px) {
.yt-collection-item[data-v-d52c1c26] {
    width: auto !important;
}
.hp-collection-slider[data-v-d52c1c26] {
    position: relative;
}
.product.yt-collection-component.yt-collection-wrap[data-v-d52c1c26] {
    padding-top: 52px !important;
}
.yt-produstslider-info[data-v-d52c1c26] {
    position: absolute;
    top: 18px;
    left: 20px;
    right: 20px;
    /* z-index: 5; */
}
.yt-slider-left[data-v-d52c1c26],
  .yt-slider-right[data-v-d52c1c26] {
    top: 17px;
    /* z-index: 10; */
}
.yt-component-more[data-v-d52c1c26] {
    border-bottom: none;
    font-size: 15px;
    line-height: 1.2;
    padding: 0 !important;
    margin: 0 !important;
}
.yt-comonent-top-title[data-v-d52c1c26] {
    font-size: 17px;
    line-height: 1.12;
}
.yt-slider-left[data-v-d52c1c26] {
    right: 41px;
}
.yt-comonent-link[data-v-d52c1c26] {
    margin-right: 16%;
}
section.hp-collection-slider[data-v-d52c1c26] {
    margin: 10px 0 !important;
}
}
@media (min-width: 1366px) and (max-width: 1900px) {
.yt-collection-title[data-v-d52c1c26] {
    font-size: 18px;
}
}
