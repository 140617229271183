:root {
  --color-primary: #ff5e00;
  --color-text-primary: #ffffff;
  --color-white: #ffffff;
  --color-black: #3e454f;
  --color-dark-grey: #8b8f95;
  --color-grey: #c9c9c9;
  --color-dark-black: #242724;
  --color-green: #15aa71;
  --color-orange: #e65e52;
  --color-blue: #30458c;
  --color-gold: #ffd700;
  --color-default: #3e454f;

  --button-primary: #ff5e00;
  --button-text-primary: #ffffff;

  --menu-title-text: dimgray;
  --menu-title-text-active: #ff5e00;

  --slider-arrow: lightgray;
}
#root br {
  display: none;
}
.styles_scroll-to-top__2A70v{
  bottom:200px !important
}

