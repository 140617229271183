#progressBarContainer[data-v-505ee9cb] {
  position: -webkit-sticky;
  position: sticky;
  z-index: 11;
  background: white;
  width: 100%;
  top: 0;
  left: 0;
}
#progressBar[data-v-505ee9cb] {
  background-image: linear-gradient(315deg, #bf033b 0%, #ffc719 74%);
  -webkit-transform-origin: top left;
          transform-origin: top left;
  -webkit-transform: scale(0, 0);
          transform: scale(0, 0);
  opacity: 0;
}
#progressBarContainer[data-v-505ee9cb],
#progressBar[data-v-505ee9cb] {
  height: 5px;
}
