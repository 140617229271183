.yt-3banner-title[data-v-bd63de02] {
  font-size: 40px;
  font-weight: 500;
  line-height: 1.15;
  margin-top: 0 !;
  color: var(--color-primary);
  font-size: 80px;
  font-weight: bold;
  text-align: left !important;
}
.hoverMe[data-v-bd63de02]{
  cursor: pointer;
}
.yt-3banner-sub-title[data-v-bd63de02] {
  /* margin-left: -85% !important; */
  font-size: 22px;
  line-height: 1.14;
  color: var(--color-RegularActiveText);
  font-weight: 500;
  padding-bottom: 35px;
  text-align: left !important;
}
.yt-3banner-sp-btn[data-v-bd63de02] {
  /* margin-left: -90% !important; */
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  letter-spacing: 0.36px;
  color: var(--button-text-primary);
  width: 100%;
  max-width: 180px;
  text-align: center;
  float: left;
}

/* .banner-wrapper {
  padding: 140px 0px 180px 138px;
} */
.yt-full-width[data-v-bd63de02] {
  max-width: 100%;
  padding-top: 7%;
  padding-left: 5%;
}
.yty-bg-3banner[data-v-bd63de02] {
  height: 600px;
  background-size: cover;
  background-position: 100%;
  position: relative;
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.yt-first-bottom-banner[data-v-bd63de02] {
    min-height: 330px;
}
.yty-bg-3banner[data-v-bd63de02] {
    height: 180px;
}
.yt-3banner-title[data-v-bd63de02] {
    font-size: 22px;
    line-height: 1.14;
}
.yt-3banner-sub-title[data-v-bd63de02] {
    font-size: 14px;
    line-height: 1.14;
    padding-bottom: 15px;
}
.yt-3banner-sp-btn[data-v-bd63de02] {
    font-size: 16px;
    line-height: 1.13;
    padding: 10px !important;
}
.yt-top-2 .yt-3banner-title[data-v-bd63de02] {
    font-size: 30px;
    line-height: 1.13;
}
.yt-top-2 .yt-3banner-sub-title[data-v-bd63de02] {
    font-size: 14px;
    line-height: 1.14;
}
.yt-3banner-big-title[data-v-bd63de02] {
    font-size: 14px;
    line-height: 1.14;
}
.yt-3banner-big-sub-title[data-v-bd63de02] {
    font-size: 40px;
    line-height: 1.15;
}
.yt-3banner-wrapper[data-v-bd63de02] {
    top: 19px;
}
.yt-3banner-divider.yt-first-divider[data-v-bd63de02] {
    margin: 12px 0;
}
.yt-3banner-divider.yt-second-divider[data-v-bd63de02] {
    margin: 20px 0 16px;
}
.yt-3banner-divider.yt-third-divider[data-v-bd63de02] {
    margin: 23px 0 16px;
}
}

/*
* Screen for 768 Resolution 
*/
@media (max-width: 767.98px) {
.promo-3-botom-info[data-v-bd63de02] {
    bottom: 30px;
}
.promo-3-img[data-v-bd63de02] {
    margin-bottom: 10px !important;
}
.yt-3banner-wrapper[data-v-bd63de02] {
    max-width: 100%;
}
.yt-3banner-big-title[data-v-bd63de02] {
    margin-bottom: 10px;
}
.yty-bg-3banner[data-v-bd63de02] {
    background-position: center;
}
.yt-first-bottom-banner[data-v-bd63de02] {
    background-position: center;
}
.yt-cm-three-col[data-v-bd63de02] {
    width: 100%;
}
.yt-first-bottom-banner[data-v-bd63de02] {
    min-height: 322px;
}
.yt-cm-three-col[data-v-bd63de02] {
    min-height: 379px;
}
.yt-top-2 .yt-3banner-title[data-v-bd63de02] {
    font-size: 20px;
    line-height: 1.15;
}
.yt-3banner-sp-btn[data-v-bd63de02] {
    font-size: 14px;
    line-height: 1.14;
    max-width: 120px;
}
.yt-3promoo-main-wrapper[data-v-bd63de02] {
    margin: 0;
}
}